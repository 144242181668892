import React, { Component, Fragment } from "react";
import { injectIntl } from 'react-intl';
import { Row,Card, CardBody,Button, CustomInput,FormGroup, Input,DropdownItem, Badge, DropdownMenu} from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import IntlMessages from "../../../helpers/IntlMessages";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import swal from 'sweetalert';
import {REST_API_END_POINT, dataEncryptKey,JQUERY_MODULE,themeColor, LBS_CONSTANT} from "../../../constants/defaultValues";
import axios from 'axios';
import uniqueId from 'lodash/uniqueId';
import SimpleCrypto from "simple-crypto-js";
import { NavLink } from "react-router-dom";

var dataCrypto = new SimpleCrypto(dataEncryptKey);


class Warehouseview extends Component {
	
	constructor(props) {
		super(props);
		this.dataListRender = this.dataListRender.bind(this);
		
		this.state = {
			shelf:null,
			warehouse_packages:[],
		};
	}
	
	componentDidMount(){
		this.dataListRender();
	}


	dataListRender() {		   
		var formData = new FormData();
		let shelf_structures;
		
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
		var formData = new FormData();
		formData.append('facility_id',facility_id);
		formData.append('shelf_id',this.props.match.params.shelf_id);		
		
		axios.post(REST_API_END_POINT+'warehouse/view-shelf',formData).then((res)=>{
			var data = res.data;
					   
			if(data.shelf){
				this.setState({
					shelf: data.shelf,
					warehouse_packages: data.package_nos_data
				});
			}else{
				this.setState({
					shelf: null,
					warehouse_packages: []
				});
			}
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
    render() {
		
	var GridContent=[];
	let i,p;
	if(this.state.shelf!=null){
		var rows=this.state.shelf['capacity_detail'].split(',');
		var warehouse_packages = this.state.warehouse_packages;
		
		var j=0;
		if(rows.length){
			let k=1;
			for(p = 0; p < parseInt(rows[0]); p++){
				GridContent.push(<div key={uniqueId()} className="row special-full-row icon-cards-row mb-2" ></div>);
				let i=0;
				for(i = 0; i < parseInt(rows[1]); i++){
					if(typeof warehouse_packages[k]!='undefined'){						
						let products = warehouse_packages[k];
						
						GridContent.push(<Card className="mb-3 mr-3 shelf-package-width" key={uniqueId()}>
											<CardBody className="text-center pt-1 pb-1">
												{products.map(product=>{
													var qty_type='Units', quantity = Math.round(product['quantity']);
													
													let product_barcode = product['barcode'];
													let product_name = product['product_name'];
													
													if(product['package_type']==1){
														if(product['qty_type']==1){
															qty_type='Grams';
															quantity = product['quantity'];
														}else if(product['qty_type']==2){
															// qty_type='Lbs';	
															qty_type='Pound';	
															quantity = Math.round(product['quantity']/LBS_CONSTANT,2);
														}else{
															qty_type='Units';
															quantity = Math.round(product['quantity']);
														}
													}
													return(<div className="shelf-product-package">
														{product_name}<br/>
														{product_barcode} {'('+quantity+" "+qty_type+')'}
													</div>)
												})}
												
												
											</CardBody>
										</Card>);
										
						j++;
					}else{
						GridContent.push(<Card className="mb-3 mr-3 shelf-package-width center-content-shelf" key={uniqueId()} >
							<CardBody className="text-center pt-1 pb-1">
								<h1>{k}</h1>
							</CardBody>						  
						</Card>);
					}
					k++;
				}
				//GridContent+='</div>';
			}
		}
					
	}
	
	
	
    return (
      <Fragment>
        <Row>		
          <Colxx xxs="12">            
			{this.state.shelf!=null ? <Breadcrumb heading_text={this.state.shelf.name} match={this.props.match} />:<Breadcrumb heading="" match={this.props.match} />}
            <Separator className="mb-5" />
          </Colxx>
        </Row>
		
		<Card className="p-4">
		   <Row>
				<Colxx xxs="12">
					<div id="api_message"></div>
				</Colxx>
				
				<Colxx xxs="12">					
					<div className="mb-4 dropdown-edit" style={{minHeight:'400px'}}>
						<Row className="icon-cards-row mb-2">
							{GridContent}							
						</Row> 
					</div>				
				</Colxx>						  
			</Row>
		</Card>
      </Fragment>
    );
  }
}

export default injectIntl(Warehouseview);