import React from "react";
import {
  Card,
  CardBody,
  CardTitle
} from "reactstrap";

import ChartComponent, { Chart } from "react-chartjs-2";
import { ThemeColors } from '../../helpers/ThemeColors';
import { chartTooltip } from '../../components/charts/util';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, projectTitle} from "../../constants/defaultValues";
const colors = ThemeColors();

const barChartOptions = {
	legend: false,
	responsive: true,
	maintainAspectRatio: false,
	scales: {
		yAxes: [
		  {
			gridLines: {
			  display: true,
			  lineWidth: 1,
			  color: 'rgba(0,0,0,0.1)',
			  drawBorder: false
			},
			ticks: {
			  beginAtZero: true,
			  min: 100,
			  padding: 20
			}
		  }
		],
		xAxes: [
		  {
			gridLines: {
			  display: false
			}
		  }
		]
	},
	tooltips: chartTooltip
}

class BarChart extends React.Component {
	componentWillMount() {
		if (this.props.shadow) {
		  Chart.defaults.barWithShadow = Chart.defaults.bar;
		  Chart.controllers.barWithShadow = Chart.controllers.bar.extend({
			draw: function(ease) {
			  Chart.controllers.bar.prototype.draw.call(this, ease);
			  var ctx = this.chart.ctx;
			  ctx.save();
			  ctx.shadowColor = "rgba(0,0,0,0.2)";
			  ctx.shadowBlur = 7;
			  ctx.shadowOffsetX = 5;
			  ctx.shadowOffsetY = 7;
			  ctx.responsive = true;
			  Chart.controllers.bar.prototype.draw.apply(this, arguments);
			  ctx.restore();
			}
		  });
		}
	}

	render() {
		const { data, shadow } = this.props;
		return (
		  <ChartComponent
			ref={ref => (this.chart_instance = ref && ref.chart_instance)}
			type={shadow ? "barWithShadow" : "bar"}
			options={{
			  ...barChartOptions
			}}
			data={data}
		  />
		);
	}
}

const TotalEarning = ({ data }) => {	
	
	var labels =[];
	var dataset_data =[];
	for(let key in data){
		labels.push(key.toString());
		dataset_data.push(data[key]);
	}
	
    const barChartData = {
	  labels: labels,
	  datasets: [
		{
		  label: 'Sales',
		  borderColor: colors.themeColor1,
		  backgroundColor: colors.themeColor1_10,
		  data: dataset_data,
		  borderWidth: 2
		}
	  ]
	}
	var dataCrypto = new SimpleCrypto(dataEncryptKey);
	var facility_id = localStorage.getItem('facility_id');
	facility_id =  dataCrypto.decrypt(facility_id);
	return(
		<Card style={{"height": "320px"}}>
				{	facility_id == 11?
			<CardBody>
				<CardTitle>
					This Month Earning
				</CardTitle>
				<div style={{"height": "200px"}}>
					<BarChart shadow={false} data={barChartData} />
				</div>
			</CardBody>:
			facility_id == 6?
			<CardBody>
				<CardTitle>
				Total Yield Monthly 
				</CardTitle>
				<div style={{"height": "200px"}}>
					<BarChart shadow={false} data={barChartData} />
				</div>
			</CardBody>:
			facility_id == 9?
			<CardBody>
				<CardTitle>
				Total Yield Monthly 
				</CardTitle>
				<div style={{"height": "200px"}}>
					<BarChart shadow={false} data={barChartData} />
				</div>
			</CardBody>:""
}
		</Card>
	);
}

export default TotalEarning;
