import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,FormGroup, Input
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import swal from 'sweetalert';
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, LBS_CONSTANT} from "../../constants/defaultValues";

import DataTablePagination from "../../components/DatatablePagination";
import ReactTable from "react-table";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class InventoryAuditAdjustModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		
		this.state = {
			search: '',
			rows:[]
		};	
	}
	
	componentDidMount() {		
		
	
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        formData.append('product_id',this.props.product_id);
        formData.append('variation_id',this.props.variation_id);
        
		axios.post(REST_API_END_POINT+'inventory/get-audit-details',formData).then((res)=>{
			var data = res.data;
		
			var rows = data.data.rows;				
			
			this.setState({
				rows
			});	
			
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
		
	handleSubmit(event, errors, values) {
		if (errors.length === 0) {
			document.getElementById("submit-inventory-audit").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("inventoryAdjustForm"));
			
			axios.post(REST_API_END_POINT+'inventory/inventory-audit-adjust',formData).then((res)=>{
				var data = res.data;
				swal("Success",data.msg,"success").then(()=>{
					this.props.toggleModal();
				})
				document.getElementById("submit-inventory-audit").disabled=false;				
				this.props.dataListRender();
				
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("submit-inventory-audit").disabled=false;
			});

		}
	}
  
  render() {
 
		const { } = this.state;
		
		let data = this.state.rows
		if (this.state.search) {
			data = data.filter(row => {
				if(row.batch_uid==null){
					row.batch_uid="";
				}
				if(row.barcode==null){
					row.barcode="";
				}
				return row.barcode.toLowerCase().includes(this.state.search) || row.batch_uid.toLowerCase().includes(this.state.search)
			})
		}
		
		const dataTableColumns = [		
			
			{
				Header: "Barcode",
				accessor: "barcode",
				width:180,
				Cell: props =>{ let barcode=''; if(props.original['source']>=3 && props.original['source']<=5){ barcode=props.original['batch_uid']; }else{ barcode=props.original['barcode']; } return  <p className="list-item-heading">{barcode}</p> }
			},
			{
				Header: "Available Quantity",
				accessor: "quantity",
				width:140,
				Cell: props =>{
					var qty_type='Grams';
					var quantity = props.original['quantity'];
					if(props.original['qty_type']==1){
						qty_type='Grams';
						quantity = props.original['quantity'];
					}else if(props.original['qty_type']==2){
						qty_type='Lbs';	
						quantity = (parseFloat(props.original['quantity'])/LBS_CONSTANT).toFixed(2);
					}else{
						qty_type='Units';
						quantity = parseInt(props.original['quantity']);
					}
					return <p className="list-item-heading">{quantity+" "+qty_type}</p>
				}
			},
			{
				Header: "Actual Quantity",
				accessor: "quantity",
				width:140,
				Cell: props => {
						var qty_type='Grams';
						var quantity = props.original['quantity'];
						if(props.original['qty_type']==1){
							qty_type='Grams';
							quantity = props.original['quantity'];
						}else if(props.original['qty_type']==2){
							// qty_type='Lbs';	
							qty_type='Pounds';	
							quantity = (parseFloat(props.original['quantity'])/LBS_CONSTANT).toFixed(2);
						}else{
							qty_type='Units';
							quantity = parseInt(props.original['quantity']);
						}
						return <Fragment>
							<AvField						
								id={"audit_quantity_"+props.original.package_id}
								name={"audit_quantity["+props.original.package_id+"]"}
								type="number"
								autoComplete="off"
								value={quantity}
								validate={{
									required: {
										value: true,
										errorMessage: "This field is required."
									},
									min: {
										value: "0",
										errorMessage: "Value should be greater than or equal to 0"
									}
								}}
							/>
							<input id={"package_id_"+props.original.package_id} type="hidden" name="package_id[]" value={props.original.package_id} required />
					  </Fragment>
					
				}
			},
			{
				Header: "Reason",
				accessor: "package_id",
				width:180,
				Cell: props => {
						return <Fragment>
							<AvField						
								id={"reason_"+props.original.package_id}
								name={"reason["+props.original.package_id+"]"}
								type="textarea"
								rows={2}
								autoComplete="off"								
								
							/>
					  </Fragment>
					
				}
			}
		];
		
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
			<ModalHeader toggle={this.props.toggleModal}>
				Adjust Inventory
			</ModalHeader>
			<AvForm
				className="av-tooltip tooltip-right-bottom"
				id="inventoryAdjustForm"
				onSubmit={this.handleSubmit}
			>
				<ModalBody>
					<Row>			  
						<Colxx xxs="12">
							<div id="api_message"></div>
							<div className="mb-4 dropdown-edit small-padd-table">
								<FormGroup row>
									<Colxx lg="6">
										<Input
										  type="text"
										  name="table_search"
										  id="table_search"
										  placeholder="Search"
										  value={this.state.search}
										  onChange={e => this.setState({search: e.target.value.toLowerCase()})}
										/>
									</Colxx>
								</FormGroup>			
								<ReactTable
									data={data}
									columns={dataTableColumns}
									minRows={3}
									defaultPageSize={100}
									filterable={false}
									showPageJump={false}
									PaginationComponent={DataTablePagination}
									showPageSizeOptions={false}
									defaultSorted={[{id: "send_on",desc: false}]}
								/>
							</div>
						</Colxx>
					</Row>
					
				</ModalBody>
				<ModalFooter>
					<Button color="primary" id="submit-inventory-audit">
						<IntlMessages id="customer.submit" />
					</Button>
					<Button color="secondary" type="button" outline onClick={this.props.toggleModal}>
						<IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
			</AvForm>
	  </Modal>
	  );
  }
};

